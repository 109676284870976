export default class BlogIndex {
	
	constructor () {
		this.container = document.getElementById("thumbsContainer");
		this.isLoading = false;
		this.currentPage = 1;
		this.totalPages = window.totalPages;
		this.categoryId = window.categoryId;
		
		if (this.currentPage !== this.totalPages)
			window.addEventListener("scroll", this.onScroll);
	}
	
	onScroll = () => {
		if (this.isLoading)
			return;
		
		if (window.scrollY >= document.body.offsetHeight - window.innerHeight * 1.5) {
			this.loadMore();
		}
	};
	
	loadMore () {
		this.isLoading = true;
		this.currentPage++;
		
		if (this.currentPage === this.totalPages)
			window.removeEventListener("scroll", this.onScroll);
		
		let request = new XMLHttpRequest();
		
		request.onload = () => {
			this.isLoading = false;
			const div = document.createElement("div");
			div.innerHTML = request.response;
			this.container.appendChild(div.firstElementChild);
		};
		
		request.open(
			'GET',
			`${window.baseUrl}blog/results?p=${this.currentPage}&category=${this.categoryId}`,
			true
		);
		request.send();
	}
	
}
