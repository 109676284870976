import $ from "./$";
import BlogIndex from "./BlogIndex";
import Calculator from "./Calculator";

let body;

function services() {
	$.array($.all('.services--nav a')).forEach(function (el) {
		el.addEventListener('click', function (e) {
			e.preventDefault();

			$.scrollTo($.id(el.getAttribute('data-service')));
		});
	});
}

function contact() {
	const form = $.id('contactForm')
		, success = $('.message.success')
		, error = $('.message.error');
	form.addEventListener('submit', function (e) {
		e.preventDefault();

		$.add(success, 'hidden');
		$.add(error, 'hidden');
		$.add(form, 'working');

		let t = this;

		let serial = 'fromName=' + encodeURIComponent(t.elements.name.value) +
			'&message[Name]=' + encodeURIComponent(t.elements.name.value) +
			'&message[Email]=' + encodeURIComponent(t.elements.email.value) +
			'&fromEmail=' + encodeURIComponent(t.elements.email.value) +
			'&' + $.serialize(t);

		let newletterSerial = '&cm-name=' + encodeURIComponent(t.elements.name.value) +
			'&cm-dtetdl-dtetdl=' + encodeURIComponent(t.elements.email.value);

		$.ajax('post', '/', serial, function (res) {
			res = JSON.parse(res);

			// Success
			if (res.success) {
				$.remove(form, 'working');
				$.remove(success, 'hidden');
				t.reset();

				setTimeout(function () {
					$.add(success, 'hidden');
				}, 10000);

				// Subscribe
				if (t.elements.subscribe.checked) {

					let ud = '_' + Math.floor(Date.now() / 1000),
						scr = document.createElement('script');
					
					window[ud] = function (/*data*/) {
						body.removeChild(scr);
					};
					
					scr.src = 'http://email.campaignsender.co.uk/t/r/s/dtetdl/?callback=' + ud + newletterSerial;
					body.appendChild(scr);
				}

			// Error
			} else {
				let ul = $('ul', error);
				ul.innerHTML = '';

				for (let err in res.error) {
					if (res.error.hasOwnProperty(err)) {
						let li = document.createElement('li');
						li.innerText = res.error[err][0];
						ul.appendChild(li);
					}
				}

				$.remove(form, 'working');
				$.remove(error, 'hidden');
			}

			$.scrollTo(t);
		});
	});
}

function mobile () {
	$.id('toggleNav').addEventListener("click", e => {
		e.preventDefault();
		document.body.classList.toggle("nav-open");
	});
}

function homepage () {
	
	let activeModal = null;
	
	if (!window.isMobile) {
		
		document.addEventListener("keydown", e => {
			if (e.keyCode === 27 && activeModal) {
				activeModal.classList.remove("show");
				document.body.classList.remove("modal-open");
			}
		});
		
		// Rates Modal
		const ratesModalTrigger = document.getElementById("ratesModalTrigger")
			, ratesModal        = document.getElementById("ratesModal");
		
		ratesModalTrigger.addEventListener("click", e => {
			e.preventDefault();
			ratesModal.classList.add("show");
			document.body.classList.add("modal-open");
			
			activeModal = ratesModal;
		});
		
		ratesModal.addEventListener("click", e => {
			if (e.target !== ratesModal)
				return;
			
			ratesModal.classList.remove("show");
			document.body.classList.remove("modal-open");
			
			activeModal = null;
		});
		
		// Calc Modal
		const calcModalTrigger = document.getElementById("calcModalTrigger")
			, calcModal        = document.getElementById("calcModal");
		
		calcModalTrigger.addEventListener("click", e => {
			e.preventDefault();
			calcModal.classList.add("show");
			document.body.classList.add("modal-open");
			
			activeModal = calcModal;
		});
		
		calcModal.addEventListener("click", e => {
			if (e.target !== calcModal)
				return;
			
			calcModal.classList.remove("show");
			document.body.classList.remove("modal-open");
			
			activeModal = null;
		});
		
	}
	
}

$.ready(function () {
	body = document.body;

	new Calculator();
	if ($.has(body, 'homepage')) homepage();
	if ($.has(body, 'services')) services();
	if ($.has(body, 'blog')) new BlogIndex();
	if ($.id('contactForm')) contact();
	if ($.id('toggleNav')) mobile();
});

// Built by Ether
if (window.console) {
	let byEther = [
		'%c %c %c %c  Built by Ether :: ethercreative.co.uk  %c %c %c %c',
		'background: rgba(214, 45, 50, 0.25)',
		'background: rgba(214, 45, 50, 0.5)',
		'background: rgba(214, 45, 50, 0.75)',
		'color: #ffffff; background: rgba(214, 45, 50, 1);',
		'background: rgba(214, 45, 50, 0.75)',
		'background: rgba(214, 45, 50, 0.5)',
		'background: rgba(214, 45, 50, 0.25)',
		'background: #ffffff'
	];
	/* eslint-disable no-console, no-empty */
	try { console.log.apply(console, byEther); } catch (e) {}
	/* eslint-enable no-console, no-empty */
}
