export default class Calculator {
	
	constructor () {
		const calc = document.getElementById("calculator")
			, calcOpen = document.getElementById("calculatorOpen");
		
		if (!calc || !calcOpen) return;
		
		document.getElementById("calculatorClose").addEventListener("click", () => {
			document.body.classList.remove("calc-open");
		});
		
		calcOpen.addEventListener("click", e => {
			e.preventDefault();
			document.body.classList.add("calc-open");
		});
		
		this.steps = [].slice.call(calc.getElementsByClassName("calculator--step"));
		this.fields = [].slice.call(calc.querySelectorAll("input, select"));
		this.activeStep = 0;
		this.fieldState = {};
		this.skipSteps = [];
		
		// Totals
		this.overallTotal = document.getElementById("overallTotal");
		this.total25 = document.getElementById("total25");
		this.total30 = document.getElementById("total30");
		this.total35 = document.getElementById("total35");
		
		[].slice.call(calc.querySelectorAll("button[data-next]")).forEach(btn => {
			btn.addEventListener("click", this.onNextStep);
		});
		
		[].slice.call(calc.querySelectorAll("button[data-prev]")).forEach(btn => {
			btn.addEventListener("click", this.onPrevStep);
		});
		
		[].slice.call(calc.querySelectorAll("button[data-reset]")).forEach(btn => {
			btn.addEventListener("click", this.onReset);
		});
		
		this.fields.forEach(field => {
			field.addEventListener("change", this.onFieldChange);
			field.addEventListener("keydown", e => {
				e.keyCode === 13 && this.onNextStep();
			});
			this.onFieldChange({ target: field }, false);
		});
	}
	
	updateSteps (fieldName) {
		if (fieldName === "plan") {
			if (this.fieldState.plan === "buyingABuyToLetProperty") {
				this.skipSteps = [1];
				this.steps[1].querySelector("button[data-next]").textContent = "Next Question >";
			} else {
				this.skipSteps = [2, 3];
				this.steps[1].querySelector("button[data-next]").textContent = "Get Answer >";
			}
		}
	}
	
	// Events
	// =========================================================================
	
	onNextStep = () => {
		if (this.activeStep === this.steps.length - 1)
			return;
		
		this.steps[this.activeStep].classList.add("leave");
		do {
			this.activeStep++;
		} while (this.skipSteps.indexOf(this.activeStep) !== -1);
		this.steps[this.activeStep].classList.remove("enter");
		this.steps[this.activeStep].querySelector("input, select").focus();
	};
	
	onPrevStep = () => {
		if (this.activeStep === 0)
			return;
		
		this.steps[this.activeStep].classList.add("enter");
		do {
			this.activeStep--;
		} while (this.skipSteps.indexOf(this.activeStep) !== -1);
		this.steps[this.activeStep].classList.remove("leave");
		this.steps[this.activeStep].querySelector("input, select").focus();
	};
	
	onReset = () => {
		this.fields.forEach(field => {
			field.value = "";
			
			if (field.firstElementChild)
				field.value = field.firstElementChild.value;
			
			this.onFieldChange({ target: field }, false);
		});
		
		this.steps.forEach((step, i) => {
			if (i) step.classList.add("enter");
			step.classList.remove("leave");
		});
		
		this.activeStep = 0;
		
		this.calculate();
	};
	
	onFieldChange = ({ target }, doCalculate = true) => {
		this.fieldState[target.name] = target.value;
		this.updateSteps(target.name);
		doCalculate && this.calculate();
	};
	
	// Calculate
	// =========================================================================
	
	calculate () {
		const { plan, income, propertyValue, rent } = this.fieldState;
		
		let overallTotal = 0;
		
		switch (plan) {
			case "buyingMyFirstHome":
				overallTotal = income * 4.5;
				break;
			case "movingHouse":
			case "remortgaging":
				overallTotal = income * (+income >= 80000 ? 5.5 : 5);
				break;
			case "buyingABuyToLetProperty": {
				let p = (propertyValue / 100) * 75,
					r = (rent * 12) / 6 * 100;
				overallTotal = p < r ? p : r;
				break;
			}
		}
		
		this.overallTotal.textContent = Calculator.format(overallTotal);
		
		this.total25.textContent = Calculator.format(Calculator.calcAround(overallTotal, 25)) + "/pm";
		this.total30.textContent = Calculator.format(Calculator.calcAround(overallTotal, 30)) + "/pm";
		this.total35.textContent = Calculator.format(Calculator.calcAround(overallTotal, 35)) + "/pm";
	}
	
	// Misc
	// =========================================================================
	
	static format (amount) {
		return amount.toLocaleString(
			'en-GB',
			{ style: 'currency', currency: 'GBP' }
		).replace(".00", "");
	}
	
	static calcAround (amount, years) {
		return Math.ceil(Math.floor(amount / years / 12) / 10) * 10;
	}
	
}
